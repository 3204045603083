<template>
  <div>
    <a-card>
      <template slot="title">
        <span class="table-title">账号管理</span>
        <a-button type="primary" icon="plus" @click="showModal('add')"
          >新增</a-button
        >
      </template>
      <a-table
        :loading="loading"
        row-key="id"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange"
      >
        <span slot="status" slot-scope="text">
          <a-tag :color="text == 1 ? '#87d068' : '#f50'">{{
            text == 1 ? "启用" : "禁用"
          }}</a-tag>
        </span>
        <span slot="action" slot-scope="record">
          <a-button type="link" @click="showModal('edit', record.id)"
            >编辑</a-button
          >
          <a-popconfirm
            title="您确认要删除该版本吗？"
            @confirm="del(record.id)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </a-card>
    <!-- 弹框 -->
    <a-modal
      :title="modalType == 'add' ? '新增账号' : '修改账号'"
      :width="650"
      :visible="dialog"
      :ok-text="okBtnText"
      :confirm-loading="confirmLoading"
      :mask-closable="mask"
      @ok="modalConfirm()"
      @cancel="dialog = false"
    >
      <a-spin :spinning="spinning">
        <a-form-model
          ref="modalForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-row type="flex">
            <a-col :span="14">
              <a-form-model-item label="账号" prop="uname">
                <a-input v-model.trim="form.uname" placeholder="用于登录系统" />
              </a-form-model-item>
              <a-form-model-item
                label="密码"
                prop="pword"
              >
               <!-- :prop="modalType == 'add' ? 'pword' : ''" -->
                <a-input-password
                  v-model.trim="form.pword"
                  placeholder="请输入密码"
                />
              </a-form-model-item>
              <a-form-model-item label="姓名" prop="real_name">
                <a-input
                  v-model.trim="form.real_name"
                  placeholder="请输入真实姓名"
                />
              </a-form-model-item>
              <a-form-model-item label="角色" prop="group_id">
                <a-select
                  v-model="form.group_id"
                  allow-clear
                  placeholder="请选择角色"
                >
                  <a-select-option v-for="item in roles" :key="item.id">
                    {{ item.gname }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否启用" prop="status">
                <a-switch v-model="form.status"></a-switch>
              </a-form-model-item>
            </a-col>
            <a-col :span="10" class="avatar-wrap">
              <a-upload
                :custom-request="uploadFile"
                :show-upload-list="false"
                list-type="picture-card"
                class="avatar-upload"
              >
                <img
                  v-if="form.avatar_show"
                  :src="form.avatar_show"
                  alt="avatar"
                  :onerror="require('../../assets/images/img-error.png')"
                />
                <div v-else>
                  <a-icon :type="confirmLoading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传头像</div>
                </div>
              </a-upload>
            </a-col>
          </a-row>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      searchForm: {
        page: 1,
        list_rows: 30
      },
      columns: [
        { title: '序号', key: 'id', dataIndex: 'id', align: 'center', width: 65, customRender: (text, record, index) =>`${(this.pagination.current - 1) *this.pagination.pageSize + index + 1}` },
        { title: '账号', key: 'uname', dataIndex: 'uname', align: 'center' },
        { title: '姓名', key: 'real_name', dataIndex: 'real_name', align: 'center' },
        { title: '角色', key: 'group_id', dataIndex: 'group_id', align: 'center' },
        { title: '状态', key: 'status', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
        { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current: 1,
        pageSize: 30,
        total: 0,
        showTotal: (total) => `共 ${total} 条`
      },
      datalist: [],
      // 弹框信息
      dialog: false,
      confirmLoading: false,
      mask: true,
      okBtnText: '确 定',
      modalType: 'add',
      spinning: false,
      init_form: {},
      roles: [],
      form: {
        uname: '',
        pword: '',
        real_name: '',
        avatar: '',
        avatar_show: '',
        status: true,
        group_id: undefined
      },
      rules: {
        uname: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        pword: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        real_name: [{ required: true, message: '该项为必填项，请填写', trigger: 'blur' }],
        group_id: [{ required: true, message: '该项为必填项，请选择', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.init_form = { ...this.form } // 初始化表单
    this.getAccountList()
  },
  methods: {
    getAccountList() {
      this.loading = true
      this.api.getAccountList(this.searchForm).then(res => {
        this.datalist = res.data.data
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onPageChange(pagination) {
      this.pagination = pagination
      this.searchForm.page = pagination.current
      this.searchForm.list_rows = pagination.pageSize
      this.getAccountList()
    },
    showModal(type, id) {
      this.modalType = type
      this.dialog = true
      this.allRoles()
      if (type == 'add') {
        this.form.avatar_show = ''
        this.form.avatar = ''
        this.$nextTick(() => {
          this.$refs.modalForm.resetFields();
        })
      } else {
        this.$nextTick(() => {
          this.$refs.modalForm.resetFields();
          this.form = this.init_form
          if (id) {
            this.getInfo(id)
          }
        })
      }

    },
    uploadFile(file) {
      this.okBtnText == '上传中'
      this.confirmLoading = true
      this.mask = false
      this.api.uploadFile(file.file).then(res => {
        this.confirmLoading = false
        this.okBtnText = '确 定'
        this.form.avatar = res.data.data.file_path
        this.form.avatar_show = res.data.data.file_url
      }).catch(() => {
        this.mask = true
        this.confirmLoading = false
        this.okBtnText = '确 定'
      })
    },
    modalConfirm() {
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          let param = { ...this.form }
          param.status = this.form.status ? 1 : 0

          if (this.modalType == 'add') {
            this.add(param)
          } else {
            this.edit(param)
          }
        } else {
          return false
        }
      })
    },
    add(param) {
      this.api.addAccount(param).then(res => {
        this.$message.success(res.data.msg)
        this.dialog = false
        this.confirmLoading = false
        this.getAccountList()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    edit(param) {
      param.kid = param.id
      delete param.id
      this.api.editAccount(param).then(res => {
        this.$message.success(res.data.msg)
        this.dialog = false
        this.confirmLoading = false
        this.getAccountList()
      }).catch(() => {
        this.confirmLoading = false
      })
    },
    getInfo(accountId) {
      this.spinning = true
      this.api.getAccountInfo({ kid: accountId }).then(res => {
        this.spinning = false
        let info = res.data.data
        this.form = { ...info }
        this.form.group_id = Number(this.form.group_id)
        this.form.status = info.status == 1 ? true : false
        this.form.avatar_show = info.avatar
        this.form.avatar = ''
      }).catch(() => {
        this.spinning = false
      })
    },
    del(accountId) {
      this.api.delAccount({ kid: accountId }).then(res => {
        this.$message.success(res.data.msg)
        this.getAccountList()
      })
    },
    allRoles() {
      this.api.allRoles({ category_id: 0 }).then(res => {
        this.roles = res.data.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-wrap {
  display: flex;
  padding: 4px 50px 0;
  /deep/.ant-upload {
    width: 100%;
    height: 190px;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  .ant-upload-text {
    margin-top: 10px;
  }
}
</style>